<template>
	<div>
		<div class="b-head-block">
			<div class="b-container">
				<div class="b-head-block__wrapper">
					<h1 class="b-title b-title--h1">Назначение уполномоченных</h1>
				</div>
			</div>
		</div>
		<div class="b-container">
			<div class="b-wrapper-page">
				<div :class="{ 'b-filter': true, open: open }">
					<button @click="open = !open" class="b-filter__button">
						<iconSvg v-if="!open" class="b-icon b-icon--filter icon-filter icon" />
						<span v-if="!open">Фильтр записей</span>

						<iconCloseSvg v-if="open" class="b-icon b-icon--close icon-close icon" />
						<span v-if="open">Скрыть</span>
					</button>
					<form class="b-filter__hidden" onsubmit="return false">
						<div class="b-filter__form">
							<div class="b-input b-input--dual b-input--dual b-input--clinical b-input--search input-group">
								<div v-for="(item, key) in filter" :key="key" class="b-input b-input--dual b-input--dual b-input--clinical b-input--search">
									<input
										:id="`search-filter${item.id}`"
										:placeholder="item.name"
										v-model="item.value"
										class="b-input__input-field b-input__input-field--dual b-input__input-field--dual b-input__input-field--clinical b-input__input-field--search"
										type="search"
										name="search-filter-name"
									/>
									<iconSearchSvg class="b-icon b-icon--table-search" />
									<label class="b-input__label" for="search-filter">{{ item.name }}</label>
								</div>
                                <div class="v-item-group v-slide-group v-chip-group ml-3">
									<div class="v-slide-group__wrapper">
										<div class="v-slide-group__content">
											<label aria-hidden="true" class="pt-1 pr-3 v-label" style="left: 0px; right: auto; position: relative;">
												Уполномочен подписывать документы:
											</label>
											<span class="v-chip primary v-chip--active v-chip--clickable v-chip--no-color v-size--default theme--light">
												<span class="v-chip__content">Да</span>
											</span>
											<span class="v-chip primary v-chip--active v-chip--clickable v-chip--no-color v-size--default theme--light">
												<span class="v-chip__content">Нет</span>
											</span>
											<!-- <span class="v-chip v-chip--clickable v-chip--no-color v-size--default theme--light">
												<span class="v-chip__content">Нет</span>
											</span> -->
										</div>
									</div>
								</div>
							</div>

							<button @click="searchFunc()" class="b-button b-button--fill">
								<span>Поиск</span>
							</button>
							<button @click="reset()" class="ml-2 b-button b-button--cancel">
								<span>Сбросить фильтр</span>
							</button>
						</div>
					</form>
				</div>

                <div class="mt-7">
                    <button class="b-button b-button--fill">
                        <span>+ Добавить пользователя</span>
                    </button>
                </div>

				<div class="b-table-list b-table-list--summary">
					<div class="b-table-list__top">
						<div class="b-table-list__left">
							<div class="b-table-list__all">
								Всего записей:<span>{{ allStroke }}</span>
							</div>
						</div>
					</div>
					<div class="b-table-list__table">
						<div style="position: relative; min-height: 300px" class="b-table-list__scroll b-table-list__scroll--table js-horizontal-scroll">
							<div v-if="isLoading" class="ring-load">
								<RingLoader class="ring-load-loading" color="#04092a" :size="160" />
							</div>
							<table>
								<thead>
									<tr>
										<th onselectstart="return false" onmousedown="return false" v-for="(item, key) in columns" :key="key" :style="`width: ${item.width}`">
											<span @click="sortFunc(item)" style="cursor: pointer">{{ item.name }}</span>

											<div v-show="checkColumn == item.nameEng" class="b-table-list__arrow">
												<button v-if="item.sort" class="b-table-list__top-button">
													<iconSortupSvg class="b-icon b-icon--table-button icon-sort-up" />
												</button>
												<button v-else class="b-table-list__bottom-button">
													<iconSortdownSvg class="b-icon b-icon--table-button icon-sort-up" />
												</button>
											</div>
										</th>
                                        <th>Действия</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(row, key) in list" :key="key">
										<td class="plain-text">
											{{ row.fio }}
										</td>
										<td class="plain-text">
											{{ row.login }}
										</td>
                                        <td>
											<div
												class="v-input d-inline-flex v-input--hide-details v-input--dense v-input--selection-controls v-input--switch v-input--switch--inset mt-0 pt-0"
												:class="{ 'v-input--is-label-active v-input--is-dirty primary--text': row.authorized, 'v-input--is-disabled': row.eds === null }">
												<div class="v-input__control">
													<div class="v-input__slot">
														<div class="v-input--selection-controls__input">
															<input :aria-checked="row.authorized" id="input-308" role="switch" type="checkbox" :aria-disabled="row.eds !== null" :disabled="row.eds === null ? 'disabled' : ''" value="">
															<div
																class="v-input--selection-controls__ripple"
																:class="{ 'primary--text': row.authorized }">
															</div>
															<div class="v-input--switch__track" :class="{ 'primary--text': row.authorized }"></div>
															<div class="v-input--switch__thumb" :class="{ 'primary--text': row.authorized }"></div>
														</div>
														<label for="input-308" class="v-label" :class="{ 'v-label--is-disabled': row.eds === null }" style="left: 0px; right: auto; position: relative;">
															<div>{{ `${row.authorized ? 'Да' : 'Нет'}` }}</div>
														</label>
													</div>
												</div>
											</div>
										</td>
                                        <td class="plain-text">
											<button v-if="row.eds !== null && row.authorized" type="button" class="btn-link v-btn v-btn--plain v-btn--text theme--light v-size--default primary--text">
												<span class="v-btn__content">Открытая часть ЭЦП</span>
											</button>
											<span v-else-if="row.authorized" class="hint">Загрузите ЭЦП</span>
											<span v-else>&mdash;</span>
										</td>
                                        <td>
											<iconDownloadSvg v-if="row.authorized" class="b-icon icon-upload pointer mr-2" />
											<iconEditSvg class="b-icon icon-edit pointer mr-2" :class="{ 'ml-5': !row.authorized }" />
											<iconCleanSvg class="b-icon icon-clean pointer" />
										</td>
									</tr>
								</tbody>
							</table>

							<center v-if="list.length == 0 && !isLoading" style="text-align: center">
								<p>Данных нет...</p>
							</center>
						</div>
					</div>
				</div>
				<div v-if="!isLoading" class="b-wrapper-page__bottom">
					<div class="b-pagination">
						<ul class="b-pagination__list">
							<li class="b-pagination__item-prev">
								<a
									@click="prevPage()"
									:class="{
										'b-button': true,
										'b-button--slider': true,
										'b-button--disabled': pageNumber == 0
									}"
									href="javascript:void(0);"
									title=""
								>
									<iconAngleleftSvg class="b-icon icon-angle-left" />
								</a>
							</li>

							<li class="b-pagination__item">
								<span>{{ resultCount > 0 ? pageNumber + 1 : 0 }} из {{ resultCount }} стр</span>
							</li>

							<li class="b-pagination__item-next">
								<a
									@click="nextPage()"
									:class="{
										'b-button': true,
										'b-button--slider': true,
										'b-button--disabled': pageNumber >= resultCount - 1
									}"
									href="javascript:void(0);"
									title=""
								>
									<iconAnglerightSvg class="b-icon icon-angle-left" />
								</a>
							</li>
						</ul>
					</div>
					<div class="b-number-page">
						<div class="b-number-page__text">
							Показать кол-во строк
							<span></span>
						</div>
						<div class="b-number-page__text">
							<select @change="updateListEvent()" style="width: 40px" v-model="size">
								<option :value="5">5</option>
								<option :value="10">10</option>
								<option :value="20">20</option>
								<option :value="30">30</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import iconSvg from '../../assets/icons/svg/filter.svg';
import iconCloseSvg from '../../assets/icons/svg/close.svg';
import iconSearchSvg from '../../assets/icons/svg/search.svg';
import iconAngleDownSvg from '../../assets/icons/svg/angle-down.svg';
import iconAngleleftSvg from '../../assets/icons/svg/angle-left.svg';
import iconAnglerightSvg from '../../assets/icons/svg/angle-right.svg';
import iconDownloadSvg from '../../assets/icons/svg/download.svg';
import iconSortdownSvg from '../../assets/icons/svg/sort-down.svg';
import iconSortupSvg from '../../assets/icons/svg/sort-up.svg';
import iconEditSvg from '../../assets/icons/svg/edit.svg';
import iconCleanSvg from '../../assets/icons/svg/clean.svg';
import { RingLoader } from '@saeris/vue-spinners';

export default {
	name: 'listCr',
	components: {
		RingLoader,
		iconEditSvg,
		iconCleanSvg,
		iconSvg,
		iconSearchSvg,
		iconAngleDownSvg,
		iconCloseSvg,
		iconDownloadSvg,
		iconSortdownSvg,
		iconSortupSvg,
		iconAngleleftSvg,
		iconAnglerightSvg
	},
	data: () => ({
		searchBtnFunc: false,
		isSearch: false,
		isLoading: false,
		allStroke: 0,
		open: false,
		checkColumn: '',
		filter: [
			{ name: 'ФИО', value: null, id: 'fio' },
			{ name: 'Логин', value: null, id: 'login' }
		],
		columns: [
			{
				name: 'ФИО',
				nameEng: 'fio',
				width: '22%',
				sort: false,
				click: false
			},
			{
				name: 'Логин',
				nameEng: 'login',
				width: '22%',
				sort: false,
				click: false
			},
			{
				name: 'Уполномочен подписывать документы',
				nameEng: 'authorized',
				width: '24%',
				sort: false,
				click: false
			},
			{
				name: 'Открытая часть ЭЦП',
				nameEng: 'eds',
				width: '22%',
				sort: false,
				click: false
			}
		],
		list: [],
		pageNumber: 0,
		size: 5,
		resultCount: 0,
		search: '',
		searchResult: [],
	}),
	methods: {
		sortFunc(e) {
			//очищаем всем флаг сортировки
			this.columns.forEach((el) => (el.click = false));

			this.isLoading = true;
			e.sort = !e.sort;
			e.click = true;
			this.checkColumn = e.nameEng;
		},
		pageCount(count) {
			this.resultCount = Math.ceil(count / this.size);
		},
		nextPage() {
			this.pageNumber++;
		},
		prevPage() {
			this.pageNumber--;
		},
		searchFunc() {
			this.isLoading = true;
			this.isSearch = true;
			this.searchBtnFunc = true;
			this.pageNumber = 0;
		},
		reset() {
		},
		updateListEvent() {
			this.pageNumber = 0;
		}
	},
	created() {
		this.list = [
            {
                id: '57feb011-3132-434f-943e-b6ebc9095a1c',
                fio: 'Иванов Петр Алексеевич',
                login: 'IvanovPA@mail.ru',
                authorized: true,
				eds: null
            },
			{
                id: 'ee87f9c5-7d83-4fa5-9905-57c4b489dbdd',
                fio: 'Сидорова Анна Ивановна',
                login: 'sidorovaAI@mail.ru',
                authorized: false,
				eds: 'test'
            },
			{
                id: '168567ab-3856-4891-a3f1-05e0d3835238',
                fio: 'Петрова Ольга Сергеевна',
                login: 'petrovaOS@mail.ru',
                authorized: true,
				eds: 'test'
            }
        ];

		this.allStroke = 3;
		this.resultCount = 1;
	}
};
</script>

<style scoped>
@import '../../style/vuetify.min.css';
@import '../../style/stylePages.css';

.ring-load {
	position: absolute;
	cursor: progress;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* opacity: 0.4; */
	z-index: 10;
	background-color: rgba(88, 88, 88, 0.2);
}

.ring-load-loading {
	left: 42%;
	top: 42%;
}

table {
	width: 100%;
}

.b-table-list__table td.plain-text {
	padding-top: 18px;
}

.b-table-list__table td.plain-text .hint {
	color: #9b9b9b;
}

.primary {
    background-color: #0061d9 !important;
    border-color: #0061d9 !important;
}

.primary--text {
    color: #0061d9 !important;
    caret-color: #0061d9 !important;
}

.btn-link {
    font-weight: 400;
    letter-spacing: normal;
    text-transform: none;
    text-decoration: underline;
}

.v-btn.btn-link:not(.v-btn--round).v-size--default {
	height: auto;
	padding: 0;
}

.v-btn.btn-link.v-size--default {
	font-size: 14px;
}

.v-btn .v-btn__content {
	padding-top: 1px;
}

.v-chip-group .v-label {
	line-height: 1.75rem;
}

.v-chip {
	border-color: rgba(0,0,0,.12);
    color: rgba(0,0,0,.87);
	overflow: visible;
}

.v-chip:not(.v-chip--active) {
    background: #e0e0e0;
}

.v-chip:not(.v-chip--outlined).primary {
	color: #fff;
}

.v-input--selection-controls__input {
    margin-right: 8px;
}

.v-input--switch .v-input--switch__track {
    color: rgba(0, 0, 0, 0.38);
}

.v-input--switch .v-input--switch__thumb {
    color: #FFFFFF;
}

.v-input--switch .v-label {
	font-size: 14px;
	color: #04092a;
}

.v-input--switch.v-input--dense .v-input--selection-controls__ripple {
    left: -12px;
}

.v-input--switch--inset.v-input--is-dirty .v-input--selection-controls__ripple,
.v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
    transform: translate(20px, 0) !important;
}

.v-input--is-disabled {
	opacity: 0.8;
}

.v-label--is-disabled {
	opacity: 0.6;
}
</style>
